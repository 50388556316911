@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/*body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url("Images/sideprof.jpg");
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}*/
body {
  margin: 0;
  overflow-y: scroll;
  font-family: "cursive";
  
}

#root {
  overflow-y: scroll;
  font-family: "cursive";
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
  font-family: 'cursive';
}

#splash-image {
  background-image: linear-gradient(0deg,rgb(12, 28, 44, .4) 10%,rgba(12, 28, 44, .6) 30%,rgba(12, 28, 44) 95%), url("Images/sprinter-profile.jpg");
  height: 800px;
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  overflow-y: scroll;
}
